<template>
  <div>
    <v-dialog v-model="dialog" persistent max-width="600px">
      <div class="bg-white poppins">
        <div class="modal-header py-4 align-center">
          <h4 class="mb-0 font-weight-bolder">{{ title }}</h4>
          <v-spacer></v-spacer>
          <v-btn icon @click="closeDialog">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
        <v-card-text>
          <form class="pt-4" @submit.prevent="submitEditForm">
            <v-select
              v-model="formData.box_id"
              :items="boxes"
              item-text="text"
              item-value="index"
              label="Box"
              clearable
              outlined
              :error-messages="getFieldErrors('box_id')"
              @input="$v.formData.box_id.$touch()"
              @blur="$v.formData.box_id.$touch()"
            ></v-select>
            <v-text-field
              v-model.number="formData.rate"
              label="Rate"
              clearable
              outlined
              type="number"
              :error-messages="getFieldErrors('rate')"
              @input="handleRateInput"
              @blur="$v.formData.rate.$touch()"
            ></v-text-field>
            <v-main class="text-center mt-4">
              <v-btn
                style="text-transform: unset !important"
                class="mr-4"
                color="primary"
                type="submit"
                :disabled="$v.$invalid"
              >
                Submit
              </v-btn>
              <v-btn
                style="text-transform: unset !important"
                @click="resetFormData"
              >
                Clear
              </v-btn>
            </v-main>
          </form>
        </v-card-text>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, minValue } from "vuelidate/lib/validators";
import fieldValueValidation from "@/own/mixins/fieldValueValidation.js";

export default {
  name: "EditRate",
  mixins: [validationMixin, fieldValueValidation],

  props: {
    actionFunction: {
      type: Function,
      required: true,
    },
    editType: {
      type: String,
      default: "rate",
      validator: (value) => ["rate"].includes(value),
    },
    boxes: {
      type: Array,
      required: true,
      validator: (value) =>
        value.every((box) => "text" in box && "index" in box),
    },
  },

  data() {
    return {
      oldItem: null,
      dialog: false,
      formData: this.getInitialFormData(),
    };
  },

  validations: {
    formData: {
      box_id: { required },
      rate: { required, minValue: minValue(0) },
    },
  },

  computed: {
    title() {
      return this.oldItem ? "Edit Box Rate" : "Add Box Rate";
    },
  },

  methods: {
    getInitialFormData() {
      return {
        box_id: null,
        rate: null,
      };
    },

    getFieldErrors(fieldName) {
      const errors = [];
      if (!this.$v.formData[fieldName].$dirty) return errors;

      const field = this.$v.formData[fieldName];

      if (!field.required && field.$dirty) {
        errors.push("This field is required");
      }

      if (fieldName === "rate" && !field.minValue) {
        errors.push("Rate must be greater than 0");
      }

      return errors;
    },

    handleRateInput(value) {
      this.$v.formData.rate.$touch();
      this.validateMinValue("rate", 0);
    },

    closeDialog() {
      this.dialog = false;
      this.resetFormData();
    },

    submitEditForm() {
      this.$v.$touch();
      if (this.$v.$invalid) return;

      this.actionFunction({ ...this.formData }, this.oldItem || false);
      this.closeDialog();
    },

    resetFormData() {
      this.$v.$reset();
      this.formData = this.oldItem
        ? { ...this.oldItem }
        : this.getInitialFormData();
    },

    toggleModal(oldItem = null) {
      this.oldItem = oldItem ? { ...oldItem } : null;
      this.resetFormData();
      this.dialog = !this.dialog;
    },
  },
};
</script>

<style scoped>
.modal-header {
  border-bottom: 1px solid #eee;
}
</style>
