<template>
  <div>
    <v-dialog v-model="dialog" persistent max-width="600px">
      <!-- <template v-slot:activator="{ on, attrs }">
        <v-btn color="primary" dark v-bind="attrs" v-on="on">
          Open Dialog
        </v-btn>
      </template> -->
      <div class="bg-white poppins">
        <div class="modal-header py-4 align-center">
          <h4 class="mb-0 font-weight-bolder">Additional costs</h4>
          <v-spacer></v-spacer>
          <v-btn icon @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
        <v-card-text>
          <form class="pt-4">
            <v-text-field
              v-model="formData.name"
              label="Name"
              clearable
              outlined
              :error-messages="nameErrors"
              @input="$v.formData.name.$touch()"
              @blur="$v.formData.name.$touch()"
            ></v-text-field>
            <v-select
              v-model="formData.apply_on"
              label="Apply on"
              item-text="text"
              item-value="index"
              :items="applyOns"
              clearable
              outlined
              :error-messages="apply_onErrors"
              @input="$v.formData.apply_on.$touch()"
              @blur="$v.formData.apply_on.$touch()"
            ></v-select>
            <v-text-field
              v-model="formData.apply_on_position"
              label="Apply on position"
              clearable
              outlined
              type="number"
              @change="() => validateMinValue('apply_on_position', 0)"
              :error-messages="apply_on_positionErrors"
              @input="$v.formData.apply_on_position.$touch()"
              @blur="$v.formData.apply_on_position.$touch()"
            ></v-text-field>
            <v-text-field
              v-model="formData.flat_rate"
              label="Flat rate"
              clearable
              outlined
              type="Number"
              min="0"
              @change="() => validateMinValue('flat_rate', 0)"
              :error-messages="flat_rateErrors"
              @input="$v.formData.flat_rate.$touch()"
              @blur="$v.formData.flat_rate.$touch()"
            ></v-text-field>
            <v-text-field
              v-model="formData.percentage_rate"
              label="Percentage rate"
              clearable
              outlined
              type="Number"
              min="0"
              @change="() => validateMinValue('percentage_rate', 0)"
              :error-messages="percentage_rateErrors"
              @input="$v.formData.percentage_rate.$touch()"
              @blur="$v.formData.percentage_rate.$touch()"
            ></v-text-field>
            <v-text-field
              v-model="formData.multiplier_rate"
              label="Multiplier rate"
              clearable
              outlined
              type="Number"
              min="0"
              @change="() => validateMinValue('multiplier_rate', 0)"
            ></v-text-field>
            <!-- new city field in delivery type service if country is selected , not required -->
            <v-main class="text-center mt-4">
              <v-btn
                class="mr-4"
                style="text-transform: unset !important"
                color="primary"
                @click="submitEditForm"
              >
                Submit
              </v-btn>
              <v-btn
                style="text-transform: unset !important"
                @click="resetFormData"
              >
                Clear
              </v-btn>
            </v-main>
          </form>
        </v-card-text>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, requiredIf } from "vuelidate/lib/validators";
import fieldValueValidation from "@/own/mixins/fieldValueValidation.js";

export default {
  name: "EditRate",
  mixins: [validationMixin, fieldValueValidation],
  validations() {
    return {
      formData: {
        name: { required },
        flat_rate: {
          required: requiredIf(() => !this.formData.percentage_rate),
        },
        percentage_rate: {
          required: requiredIf(() => !this.formData.flat_rate),
        },
        apply_on: { required },
        apply_on_position: { required },
      },
    };
  },
  // props: ["item", "actionFunction"],
  props: {
    item: {},
    actionFunction: {
      type: Function,
    },
    editType: {
      type: String,
      default: "rate",
    },
    applyOns: {},
  },
  data: () => ({
    dialog: false,
    isLoading: false,
    search: null,
    count: null,
    entries: [],
    formData: {
      name: null,
      flat_rate: null,
      percentage_rate: null,
      multiplier_rate: null,
      apply_on: null,
      apply_on_position: null,
    },
  }),
  methods: {
    handleFormValidation(fieldName, vueObj) {
      const errors = [];
      if (!vueObj.$v.formData[fieldName].$dirty) return errors;
      // if ("email" in vueObj.$v[fieldName]) {
      //   !vueObj.$v[fieldName].email &&
      //     errors.push("This email address is invalid");
      // }
      if ("required" in vueObj.$v.formData[fieldName]) {
        !vueObj.$v.formData[fieldName].required &&
          errors.push("This field is required");
      }
      return errors;
    },
    submitEditForm() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      } else {
        // this.formData.system_code = this.items.map(sku =>
        //   sku.skuId == this.formData.sku_id ? sku.systemCode : null
        // )[0];
        // console.log("supermode is ", this.formData, this.items);
        this.actionFunction(
          { ...this.formData },
          this.item ? this.item : false
        );
        this.toggleModal();
      }
    },
    resetFormData() {
      this.$v.$reset();
      if (this.item) {
        let copy = { ...this.item };
        Object.entries(this.formData).forEach((localData) => {
          Object.entries(this.item).forEach((serverData) => {
            if (localData[0] == serverData[0]) {
              this.formData[localData[0]] = copy[serverData[0]];
            }
          });
        });
      } else {
        this.formData = {
          name: null,
          flat_rate: null,
          percentage_rate: null,
          multiplier_rate: null,
          apply_on: null,
          apply_on_position: null,
        };
      }
    },

    toggleModal() {
      this.resetFormData();

      this.dialog = !this.dialog;
    },
  },
  computed: {
    nameErrors: function () {
      return this.handleFormValidation("name", this);
    },
    flat_rateErrors: function () {
      return this.handleFormValidation("flat_rate", this);
    },
    percentage_rateErrors: function () {
      return this.handleFormValidation("percentage_rate", this);
    },
    apply_onErrors: function () {
      return this.handleFormValidation("apply_on", this);
    },
    apply_on_positionErrors: function () {
      return this.handleFormValidation("apply_on_position", this);
    },
  },
};
</script>
