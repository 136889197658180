<template>
  <div style="display: flex; flex-flow: wrap; flex-direction: column">
    <!--    <div class="py-4 text-h4">Fulfillment Form</div>-->
    <form id="createForm" class="pa-3 pt-4 pb-0 row elevation-0">
      <div class="col-sm-6 col-md-6 col-12 py-0 my-0">
        <v-autocomplete
          v-model="formData.warehouse_ids"
          class="multi-select"
          outlined
          :hide-no-data="!serverData.fulfillment.warehouses"
          :items="serverData.fulfillment.warehouses"
          label="Warehouses"
          item-text="text"
          item-value="index"
          multiple
          deletable-chips
          clearable
          chips
          small-chips
          :return-object="false"
          :error-messages="warehouse_idsErrors"
          @input="$v.formData.warehouse_ids.$touch()"
          @blur="$v.formData.warehouse_ids.$touch()"
        >
          <template v-slot:selection="{ attrs, item, parent, selected }">
            <v-chip
              v-if="item === Object(item)"
              v-bind="attrs"
              :input-value="selected"
              label
              small
            >
              <span class="pr-2">
                {{ item.text }}
              </span>
              <v-icon small @click="parent.selectItem(item)"> $delete </v-icon>
            </v-chip>
          </template>
        </v-autocomplete>
      </div>
      <div class="col-sm-6 col-md-6 col-12 py-0 my-0">
        <v-select
          v-model="formData.is_b2bm"
          label="B2B Order"
          item-text="text"
          item-value="index"
          :items="[
            { text: 'Yes', index: 1 },
            { text: 'No', index: 0 },
          ]"
          outlined
          @change="
            () => {
              formData.order_types = [];
            }
          "
          :error-messages="is_b2bmErrors"
          @input="$v.formData.is_b2bm.$touch()"
          @blur="$v.formData.is_b2bm.$touch()"
        ></v-select>
      </div>
      <div class="col-sm-6 col-md-6 col-12 py-0 my-0" v-if="!formData.is_b2bm">
        <v-autocomplete
          v-model="formData.order_types"
          class="multi-select"
          outlined
          :hide-no-data="!orderTypeFiltered"
          :items="orderTypeFiltered"
          label="Order types"
          item-text="text"
          item-value="index"
          multiple
          deletable-chips
          clearable
          chips
          small-chips
          :return-object="false"
          :error-messages="order_typesErrors"
          @input="$v.formData.order_types.$touch()"
          @blur="$v.formData.order_types.$touch()"
        >
          <template v-slot:selection="{ attrs, item, parent, selected }">
            <v-chip
              v-if="item === Object(item)"
              v-bind="attrs"
              :input-value="selected"
              label
              small
            >
              <span class="pr-2">
                {{ item.text }}
              </span>
              <v-icon small @click="parent.selectItem(item)"> $delete </v-icon>
            </v-chip>
          </template>
        </v-autocomplete>
      </div>
      <div class="col-sm-6 col-md-6 col-12 py-0 my-0" v-if="formData.is_b2bm">
        <v-select
          v-model="formData.unit_id"
          label="Unit"
          item-text="text"
          item-value="index"
          :items="serverData.fulfillment.units"
          clearable
          outlined
          :error-messages="unit_idErrors"
          @input="$v.formData.unit_id.$touch()"
          @blur="$v.formData.unit_id.$touch()"
        ></v-select>
      </div>
      <div
        class="col-sm-6 col-md-6 col-12 py-0 my-0"
        v-if="formData.unit_id == 7"
      >
        <v-text-field
          v-model="formData.loose_item_price"
          label="Loose item price"
          clearable
          outlined
          type="Number"
          min="1"
          :error-messages="loose_item_priceErrors"
          @input="$v.formData.loose_item_price.$touch()"
          @blur="$v.formData.loose_item_price.$touch()"
        ></v-text-field>
      </div>
      <div class="col-sm-6 col-md-6 col-12 py-0 my-0">
        <v-select
          v-model="formData.sku_type_id"
          label="SKU type"
          item-text="text"
          item-value="index"
          :items="serverData.fulfillment.sku_types"
          clearable
          outlined
          :error-messages="sku_type_idErrors"
          @input="$v.formData.sku_type_id.$touch()"
          @blur="$v.formData.sku_type_id.$touch()"
        ></v-select>
      </div>
      <div class="col-sm-6 col-md-6 col-12 py-0 my-0">
        <v-select
          v-model="formData.order_date"
          label="Invoicing order date"
          item-text="text"
          item-value="index"
          :items="serverData.fulfillment.order_dates"
          clearable
          outlined
          :error-messages="order_dateErrors"
          @input="$v.formData.order_date.$touch()"
          @blur="$v.formData.order_date.$touch()"
        ></v-select>
      </div>

      <!-- <div class="col-sm-6 col-md-6 col-lg-4 col-12">
        <v-text-field
          v-model="formData.rate"
          label="Rate"
          clearable
          outlined
          type="Number"
          min="0"
          :error-messages="rateErrors"
          @input="$v.formData.rate.$touch()"
          @blur="$v.formData.rate.$touch()"
        ></v-text-field>
      </div> -->
      <!-- <div class="col-sm-6 col-md-6 col-lg-4 col-12">
        <v-text-field
          v-model="formData.base"
          label="Base"
          clearable
          outlined
          type="Number"
          min="0"
          :error-messages="baseErrors"
          @input="$v.formData.base.$touch()"
          @blur="$v.formData.base.$touch()"
        ></v-text-field>
      </div> -->
      <!-- <div class="col-sm-6 col-md-6 col-lg-4 col-12">
        <v-text-field
          v-model="formData.increment"
          label="Increment"
          clearable
          outlined
          type="Number"
          min="0"
          :error-messages="incrementErrors"
          @input="$v.formData.increment.$touch()"
          @blur="$v.formData.increment.$touch()"
        ></v-text-field>
      </div> -->
      <!-- <div class="col-sm-6 col-md-6 col-lg-4 col-12">
        <v-text-field
          v-model="formData.add_rate"
          label="Add rate"
          clearable
          outlined
          type="Number"
          min="0"
          :error-messages="add_rateErrors"
          @input="$v.formData.add_rate.$touch()"
          @blur="$v.formData.add_rate.$touch()"
        ></v-text-field>
      </div> -->
      <!--      <div class="col-sm-6 col-md-6 col-12 py-0 my-0">-->
      <!--        <v-text-field-->
      <!--          v-model="formData.tax_percentage"-->
      <!--          label="Tax percentage"-->
      <!--          clearable-->
      <!--          outlined-->
      <!--          type="Number"-->
      <!--          min="0"-->
      <!--          @change="() => validateMinValue('tax_percentage', 0)"-->
      <!--          :error-messages="tax_percentageErrors"-->
      <!--          @input="$v.formData.tax_percentage.$touch()"-->
      <!--          @blur="$v.formData.tax_percentage.$touch()"-->
      <!--        ></v-text-field>-->
      <!--      </div>-->
      <!--      <div class="col-12 row px-0 mx-0">-->
      <!--        <div class="col-sm-6 col-md-6 col-lg-6 col-12 py-0 my-0">-->
      <!--          <v-radio-group class="py-0 my-0" row label="Discount" v-model="formData.discount_type">-->
      <!--            <v-radio label="Flat rate" :value="0"></v-radio>-->
      <!--            <v-radio label="Percentage" :value="1"></v-radio>-->
      <!--          </v-radio-group>-->
      <!--        </div>-->
      <!--        <div class="col-sm-6 col-md-6 col-lg-6 col-12 py-0 my-0">-->
      <!--          <v-text-field-->
      <!--            v-model="formData.discount"-->
      <!--            :label="discountValueLabel"-->
      <!--            clearable-->
      <!--            outlined-->
      <!--            type="Number"-->
      <!--            min="0"-->
      <!--            hide-details-->
      <!--            @change="() => validateMinValue('discount', 0)"-->
      <!--            :error-messages="discountErrors"-->
      <!--            @input="$v.formData.discount.$touch()"-->
      <!--            @blur="$v.formData.discount.$touch()"-->
      <!--          ></v-text-field>-->
      <!--        </div>-->
      <!--      </div>-->

      <div class="col-12">
        <rate-datatable
          name="rate"
          :setSku="setRates"
          :skus="formData.rates"
          :hasImport="true"
          :importType="1"
          importLabel="Import rates"
          :exampleFile="serverData?.fulfillment?.rates_sample_file"
        ></rate-datatable>
        <hr />

        <rate-datatable
          name="increment"
          :setSku="setIncrements"
          :skus="formData.increments"
          :hasImport="true"
          :importType="2"
          importLabel="Import increments"
          :exampleFile="serverData?.fulfillment?.increments_sample_file"
        ></rate-datatable>
      </div>

      <v-main class="text-center mt-2 col-12 elevation-0">
        <button
          type="button"
          class="mx-1 btn btn-info px-5 py-3 ls1"
          @click="submitCreateForm"
        >
          Submit
        </button>
        <button
          type="button"
          class="mx-1 btn btn-light px-5 py-3 ls1"
          @click="resetCreateForm"
        >
          Clear
        </button>
        <button
          type="button"
          class="mx-1 btn btn-light px-5 py-3 ls1"
          @click="goBack"
        >
          Back
        </button>
      </v-main>
    </form>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, requiredIf } from "vuelidate/lib/validators";
import fieldValueValidation from "@/own/mixins/fieldValueValidation.js";
import RateDatatable from "@/own/components/finance/services/addForms/rateContainer/RateDatatable";
// import Swal from "sweetalert2";
// import { required } from "vuelidate/lib/validators";

export default {
  name: "FulfillmentForm.vue",
  mixins: [validationMixin, fieldValueValidation],
  props: ["setData", "actionFuntion", "serverData", "pageLoader", "initData"],
  components: { RateDatatable },
  validations() {
    return {
      formData: {
        order_types: {
          required: requiredIf(() => {
            return this.formData.is_b2bm == 0;
          }),
        },
        warehouse_ids: { required },
        unit_id: {
          required: requiredIf(() => {
            return this.formData.is_b2bm == 1;
          }),
        },
        loose_item_price: {
          required: requiredIf(() => {
            return this.formData.unit_id == 7;
          }),
        },
        // rate: { required },
        // base: { required },
        // increment: { required },
        // add_rate: { required },
        order_date: { required },
        // tax_percentage: { required },
        // discount: { required },
        is_b2bm: { required },
        sku_type_id: { required },
      },
    };
  },
  data: () => ({
    formData: {
      id: null,
      unit_id: null,
      warehouse_ids: null,
      // rate: null,
      // base: null,
      // increment: null,
      // add_rate: null,
      // tax_percentage: null,
      order_date: null,
      order_types: null,
      rates: [],
      increments: [],
      // discount_type: 0,
      // discount: null,
      is_b2bm: 0,
      sku_type_id: null,
      loose_item_price: null,
    },
  }),
  methods: {
    setIncrements(item) {
      // console.log("before update", this.formData.increments, item);
      let copy = { ...this.formData };
      copy.increments = [...item];
      this.formData = copy;
      // console.log("after update", this.formData.increments);
    },
    setRates(item) {
      this.formData = { ...this.formData, rates: item };
    },
    goBack() {
      this.actionFuntion("back");
    },
    submitCreateForm() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      } else {
        this.pageLoader(true);
        // let data = JSON.stringify(this.entCreate);
        if (this.formData.is_b2bm) {
          this.formData.order_types = [30];
        }
        const data = { ...this.formData };
        this.setData(data);
        this.actionFuntion();
        // this.resetCreateForm();
      }
    },
    resetCreateForm() {
      this.$v.$reset();
      if (this.initData) {
        try {
          let copy = { ...this.serverData.service.values };
          Object.entries(this.formData).forEach((localData) => {
            Object.entries(this.serverData.service.values).forEach(
              (serverData) => {
                if (localData[0] == serverData[0]) {
                  this.formData[localData[0]] = copy[serverData[0]];
                }
              }
            );
          });
          if (
            this.formData.order_types?.length == 1 &&
            this.formData?.order_types[0] == 30
          ) {
            this.formData.is_b2bm = 1;
          }
        } catch (e) {
          // console.error(e);
          this.formData = {
            id: null,
            unit_id: null,
            warehouse_ids: null,
            // rate: null,
            // base: null,
            // increment: null,
            // add_rate: null,
            // tax_percentage: null,
            order_date: null,
            order_types: null,
            rates: [],
            increments: [],
            // discount_type: 0,
            // discount: null,
            is_b2bm: 0,
            sku_type_id: null,
            loose_item_price: null,
          };
        }
      } else {
        this.formData = {
          id: null,
          unit_id: null,
          warehouse_ids: null,
          // rate: null,
          // base: null,
          // increment: null,
          // add_rate: null,
          // tax_percentage: null,
          order_date: null,
          order_types: null,
          rates: [],
          increments: [],
          // discount_type: 0,
          // discount: null,
          is_b2bm: 0,
          sku_type_id: null,
          loose_item_price: null,
        };
      }
    },
    handleFormValidation(fieldName, vueObj) {
      const errors = [];
      if (!vueObj.$v.formData[fieldName].$dirty) return errors;
      if ("required" in vueObj.$v.formData[fieldName]) {
        !vueObj.$v.formData[fieldName].required &&
          errors.push("This field is required");
      }
      if ("url" in vueObj.$v.formData[fieldName]) {
        !vueObj.$v.formData[fieldName].url &&
          errors.push("This url is invalid");
      }
      if ("numeric" in vueObj.$v.formData[fieldName]) {
        !vueObj.$v.formData[fieldName].numeric &&
          errors.push("This must be a number");
      }
      if ("email" in vueObj.$v.formData[fieldName]) {
        !vueObj.$v.formData[fieldName].email &&
          errors.push("This email address is invalid");
      }
      return errors;
    },
  },
  computed: {
    orderTypeFiltered() {
      return this.serverData.fulfillment.order_types.filter(
        (item) => item.index != 30
      );
    },
    // discountValueLabel() {
    //   return this.formData.discount_type == 0 ? "Flat rate" : "Percentage";
    // },
    order_typesErrors() {
      return this.handleFormValidation("order_types", this);
    },
    warehouse_idsErrors() {
      return this.handleFormValidation("warehouse_ids", this);
    },
    unit_idErrors() {
      return this.handleFormValidation("unit_id", this);
    },
    // rateErrors() {
    //   return this.handleFormValidation("rate", this);
    // },
    // baseErrors() {
    //   return this.handleFormValidation("base", this);
    // },
    // add_rateErrors() {
    //   return this.handleFormValidation("add_rate", this);
    // },
    order_dateErrors() {
      return this.handleFormValidation("order_date", this);
    },
    // tax_percentageErrors() {
    //   return this.handleFormValidation("tax_percentage", this);
    // },
    // incrementErrors() {
    //   return this.handleFormValidation("tax_percentage", this);
    // },
    // discountErrors() {
    //   return this.handleFormValidation("discount", this);
    // },
    is_b2bmErrors() {
      return this.handleFormValidation("is_b2bm", this);
    },
    sku_type_idErrors() {
      return this.handleFormValidation("sku_type_id", this);
    },
    loose_item_priceErrors() {
      return this.handleFormValidation("loose_item_price", this);
    },
  },
};
</script>
