<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="items"
      class="elevation-0 py-0 my-0"
      hide-default-footer
    >
      <template #top>
        <v-toolbar flat>
          <v-toolbar-title class="ma-0 pa-0 text-capitalize">
            <h3>{{ name }}</h3>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            dark
            class="mb-2"
            style="text-transform: unset !important"
            @click="showAddSkuModal"
          >
            New {{ name }}
          </v-btn>
          <v-btn
            v-if="hasImport"
            color="primary"
            dark
            class="ml-2 mb-2"
            style="text-transform: unset !important"
            @click="showImportModal"
          >
            Import
          </v-btn>
        </v-toolbar>
      </template>

      <template #[`item.box_id`]="{ item }">
        {{ getBoxText(item.box_id) }}
      </template>

      <template #[`item.actions`]="{ item }">
        <div class="d-flex">
          <v-icon
            small
            class="mr-2"
            @click="showEditSkuModal(item)"
            v-tooltip="'Edit'"
          >
            mdi-pencil
          </v-icon>
          <v-icon small @click="confirmDelete(item)" v-tooltip="'Delete'">
            mdi-delete
          </v-icon>
        </div>
      </template>
    </v-data-table>
    <EditRate
      ref="editSku"
      :actionFunction="handleEditSku"
      editType="Box"
      :boxes="boxes"
    ></EditRate>
    <ImportData
      v-if="hasImport"
      ref="importData"
      :type="importType"
      :label="importLabel"
      :setData="setImportDataToDataTable"
      :exampleFile="exampleFile"
    >
    </ImportData>
  </div>
</template>

<script>
import EditRate from "@/own/components/finance/services/addForms/boxContainer/EditRate";
import ImportData from "@/own/components/finance/services/addForms/boxContainer/ImportData";
import Swal from "sweetalert2";

export default {
  name: "RateDatatable",
  props: {
    setSku: {
      type: Function,
      required: true,
    },
    skus: {
      type: Array,
      default: () => [],
    },
    name: {
      type: String,
      required: true,
    },
    hasImport: {
      type: Boolean,
      default: false,
    },
    importType: {
      type: Number,
      validator: (value) => [1, 2, 3, 4].includes(value),
    },
    importLabel: {
      type: String,
      default: "Import Data",
    },
    exampleFile: {
      type: String,
    },
    boxes: {
      type: Array,
      required: true,
      validator: (value) =>
        value.every((box) => "text" in box && "index" in box),
    },
  },
  components: { EditRate, ImportData },
  data: () => ({
    dialog: false,
  }),
  computed: {
    items() {
      return this.skus || [];
    },
    headers() {
      return [
        { text: "Box ID", value: "box_id" },
        { text: "Rate", value: "rate" },
        { text: "Actions", value: "actions", sortable: false },
      ];
    },
  },
  methods: {
    getBoxText(boxId) {
      const box = this.boxes.find((box) => box.index === boxId);
      return box ? box.text : "";
    },
    showEditSkuModal(item) {
      this.$refs.editSku.toggleModal(item ? { ...item } : null);
    },
    showAddSkuModal() {
      this.showEditSkuModal(null);
    },
    showImportModal() {
      this.$refs.importData.toggleModal();
    },
    async confirmDelete(item) {
      try {
        const result = await Swal.fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, delete it!",
        });

        if (result.isConfirmed) {
          this.removeFromSKus(item);
          Swal.fire("Deleted!", "The rate has been deleted.", "success");
        }
      } catch (error) {
        // console.error("Error during deletion:", error);
        Swal.fire("Error", "There was an error deleting the rate.", "error");
      }
    },
    setImportDataToDataTable(importedData) {
      this.setSku([]);
      this.setSku(importedData);
    },
    removeFromSKus(item) {
      const skusCopy = [...this.skus];
      const index = skusCopy.indexOf(item);
      if (index > -1) {
        skusCopy.splice(index, 1);
        this.setSku(skusCopy);
      }
    },
    handleEditSku(item, oldItem = false) {
      const skusCopy = [...(this.skus || [])];

      if (oldItem) {
        const index = skusCopy.findIndex(
          (sku) => sku.box_id === oldItem.box_id
        );
        if (index > -1) {
          skusCopy.splice(index, 1);
        }
      }

      const existingIndex = skusCopy.findIndex(
        (sku) => sku.box_id === item.box_id
      );
      if (existingIndex > -1) {
        skusCopy[existingIndex] = { ...item };
      } else {
        skusCopy.push({ ...item });
      }

      this.setSku(skusCopy);
    },
  },
};
</script>

<style scoped>
.v-data-table ::v-deep .v-data-table__wrapper {
  overflow-x: auto;
}

.v-btn {
  text-transform: none !important;
}
</style>
