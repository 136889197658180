<template>
  <div
    style="
      display: flex;
      flex-flow: wrap;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    "
  >
    <!--    <div class="py-4 text-h4">Storage Form</div>-->
    <form
      id="createForm"
      class="pa-3 pt-4 pb-0 row elevation-0"
      @submit.prevent="submitCreateForm"
    >
      <div class="col-lg-4 col-sm-6 col-12">
        <v-autocomplete
          v-model="formData.warehouse_ids"
          class="multi-select"
          outlined
          :hide-no-data="!serverData.additional_services.warehouses"
          :items="serverData.additional_services.warehouses"
          label="Warehouses"
          item-text="text"
          item-value="index"
          multiple
          deletable-chips
          clearable
          chips
          small-chips
          :return-object="false"
          :error-messages="getFieldErrors('warehouse_ids')"
          @input="$v.formData.warehouse_ids.$touch()"
          @blur="$v.formData.warehouse_ids.$touch()"
        >
          <template v-slot:selection="{ attrs, item, parent, selected }">
            <v-chip
              v-if="item === Object(item)"
              v-bind="attrs"
              :input-value="selected"
              label
              small
            >
              <span class="pr-2">
                {{ item.text }}
              </span>
              <v-icon small @click="parent.selectItem(item)"> $delete </v-icon>
            </v-chip>
          </template>
        </v-autocomplete>
      </div>
      <div class="col-sm-6 col-md-6 col-lg-4 col-12">
        <v-select
          v-model="formData.type"
          label="Type"
          item-text="text"
          item-value="index"
          :items="serverData.additional_services.types"
          clearable
          outlined
          :error-messages="getFieldErrors('type')"
          @input="handleTypeChange"
          @blur="$v.formData.type.$touch()"
        ></v-select>
      </div>
      <div class="col-sm-6 col-md-6 col-lg-4 col-12">
        <v-select
          v-model="formData.unit_id"
          label="Unit"
          item-text="text"
          item-value="index"
          :items="serverData.additional_services.type_units[formData.type]"
          clearable
          outlined
          :error-messages="getFieldErrors('unit_id')"
          @input="handleUnitChange"
          @blur="$v.formData.unit_id.$touch()"
        ></v-select>
      </div>

      <div
        class="col-sm-6 col-md-6 col-lg-4 col-12"
        v-if="formData.unit_id == 8"
      >
        <v-select
          v-model="formData.qty_type"
          label="Quantity type"
          item-text="text"
          item-value="index"
          :items="serverData.additional_services.qty_types"
          clearable
          outlined
        ></v-select>
      </div>

      <div class="col-sm-6 col-md-6 col-lg-4 col-12">
        <v-text-field
          v-model.number="formData.rate"
          label="Rate"
          clearable
          outlined
          type="number"
          min="0"
          :error-messages="getFieldErrors('rate')"
          @input="handleRateInput"
          @blur="$v.formData.rate.$touch()"
        ></v-text-field>
      </div>
      <div class="col-sm-6 col-md-6 col-lg-4 col-12">
        <v-text-field
          v-model.number="formData.percentage_rate"
          label="Percentage rate"
          clearable
          outlined
          type="number"
          min="0"
          :error-messages="getFieldErrors('percentage_rate')"
          @input="handlePercentageRateInput"
          @blur="$v.formData.percentage_rate.$touch()"
        ></v-text-field>
      </div>
      <div class="col-sm-6 col-12 col-lg-4" v-if="showOrderStatusSelect">
        <v-autocomplete
          v-model="formData.order_status_ids"
          class="multi-select"
          outlined
          :hide-no-data="!hasOrderStatuses"
          :items="orderStatusItems"
          :label="orderStatusLabel"
          item-text="text"
          item-value="index"
          multiple
          deletable-chips
          clearable
          chips
          small-chips
          :return-object="false"
          :error-messages="getFieldErrors('order_status_ids')"
          @input="$v.formData.order_status_ids.$touch()"
          @blur="$v.formData.order_status_ids.$touch()"
        >
          <template v-slot:selection="{ attrs, item, parent, selected }">
            <v-chip
              v-if="item === Object(item)"
              v-bind="attrs"
              :input-value="selected"
              label
              small
            >
              <span class="pr-2">
                {{ item.text }}
              </span>
              <v-icon small @click="parent.selectItem(item)"> $delete </v-icon>
            </v-chip>
          </template>
        </v-autocomplete>
      </div>

      <div v-if="showOrderTypeSelect" class="col-sm-6 col-md-6 col-lg-4 col-12">
        <v-select
          v-model="formData.order_type_id"
          label="Order type"
          item-text="text"
          item-value="index"
          :items="serverData.additional_services.order_types"
          clearable
          outlined
        ></v-select>
      </div>
      <div v-if="showBoxRates" class="col-12">
        <rate-datatable
          name="box"
          :setSku="setRates"
          :skus="formData.box_rates"
          :hasImport="true"
          :importType="4"
          importLabel="Import box types"
          :exampleFile="serverData?.additional_services?.box_rates_sample_file"
          :boxes="serverData.additional_services.boxes"
        />
      </div>
      <v-main class="text-center mt-2 col-12 elevation-0">
        <v-btn
          type="submit"
          color="primary"
          class="mx-1 px-5"
          :disabled="$v.$invalid"
        >
          Submit
        </v-btn>
        <v-btn class="mx-1 px-5" @click="resetCreateForm"> Clear </v-btn>
        <v-btn class="mx-1 px-5" @click="goBack"> Back </v-btn>
      </v-main>
    </form>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, requiredIf } from "vuelidate/lib/validators";
import fieldValueValidation from "@/own/mixins/fieldValueValidation.js";
import RateDatatable from "@/own/components/finance/services/addForms/boxContainer/RateDatatable.vue";

export default {
  name: "AdditionalForm",
  components: { RateDatatable },
  mixins: [validationMixin, fieldValueValidation],
  props: {
    setData: {
      type: Function,
      required: true,
    },
    actionFuntion: {
      type: Function,
      required: true,
    },
    serverData: {
      type: Object,
      required: true,
    },
    pageLoader: {
      type: Function,
      required: true,
    },
    initData: {
      type: Boolean,
      default: false,
    },
  },
  validations() {
    return {
      formData: {
        warehouse_ids: { required },
        unit_id: { required },
        rate: {
          required: requiredIf(() => !this.formData.percentage_rate),
        },
        percentage_rate: {
          required: requiredIf(() => !this.formData.rate),
        },
        order_status_ids: {
          required: requiredIf(() => this.formData.type === 3),
        },
        type: { required },
      },
    };
  },
  data() {
    return {
      formData: this.getInitialFormData(),
    };
  },
  computed: {
    showOrderStatusSelect() {
      return [2, 3].includes(this.formData.type);
    },

    showOrderTypeSelect() {
      return this.formData.type === 2;
    },

    showBoxRates() {
      return this.formData.unit_id === 9 && this.formData.type === 2;
    },

    hasOrderStatuses() {
      return this.orderStatusItems && this.orderStatusItems.length > 0;
    },

    orderStatusItems() {
      return this.formData.type === 2
        ? this.serverData.additional_services.order_statuses
        : this.serverData.additional_services.call_attempt_order_statuses;
    },

    orderStatusLabel() {
      return this.formData.type === 2 ? "Order status" : "Call attempt status";
    },
  },
  methods: {
    getInitialFormData() {
      return {
        id: null,
        unit_id: null,
        rate: null,
        order_status_ids: null,
        type: null,
        order_type_id: null,
        warehouse_ids: null,
        percentage_rate: null,
        box_rates: [],
        qty_type: null,
      };
    },

    getFieldErrors(fieldName) {
      const errors = [];
      if (!this.$v.formData[fieldName].$dirty) return errors;

      const field = this.$v.formData[fieldName];

      if (!field.required && field.$dirty) {
        errors.push("This field is required");
      }

      return errors;
    },

    handleTypeChange(value) {
      this.$v.formData.type.$touch();
      if (!value) {
        this.formData.order_status_ids = null;
        this.formData.order_type_id = null;
      }
    },

    handleUnitChange(value) {
      this.$v.formData.unit_id.$touch();
      if (value !== 9 || this.formData.type !== 1) {
        this.formData.box_rates = [];
      }
    },

    handleRateInput(value) {
      this.$v.formData.rate.$touch();
      this.validateMinValue("rate", 0);
    },

    handlePercentageRateInput(value) {
      this.$v.formData.percentage_rate.$touch();
      this.validateMinValue("percentage_rate", 0);
    },

    setRates(rates) {
      this.formData.box_rates = [...rates];
    },

    goBack() {
      this.actionFuntion("back");
    },

    submitCreateForm() {
      this.$v.$touch();
      if (this.$v.$invalid) return;

      this.pageLoader(true);
      const data = Object.fromEntries(
        Object.entries(this.formData).filter(([_, value]) => value !== null)
      );
      this.setData(data);
      this.actionFuntion();
    },

    resetCreateForm() {
      this.$v.$reset();
      if (this.initData && this.serverData.service?.values) {
        try {
          const serviceValues = { ...this.serverData.service.values };
          Object.keys(this.formData).forEach((key) => {
            if (key in serviceValues) {
              this.formData[key] = serviceValues[key];
            }
          });
        } catch {
          this.formData = this.getInitialFormData();
        }
      } else {
        this.formData = this.getInitialFormData();
      }
    },
  },
};
</script>

<style scoped>
.multi-select {
  width: 100%;
}

.v-btn {
  text-transform: none !important;
  height: 44px;
}
</style>
