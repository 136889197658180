<template>
  <div style="display: flex; flex-flow: wrap; flex-direction: column">
    <!--    <div class="py-4 text-h4">Storage Form</div>-->
    <form id="createForm" class="pa-3 pt-4 pb-0 row elevation-0">
      <!--      <div class="col-sm-6 col-md-6 col-lg-4 col-12">-->
      <!--        <v-select-->
      <!--          v-model="formData.unit_id"-->
      <!--          label="Unit"-->
      <!--          item-text="text"-->
      <!--          item-value="index"-->
      <!--          :items="serverData.storage_units"-->
      <!--          clearable-->
      <!--          outlined-->
      <!--          :error-messages="unit_idErrors"-->
      <!--          @input="$v.formData.unit_id.$touch()"-->
      <!--          @blur="$v.formData.unit_id.$touch()"-->
      <!--        ></v-select>-->
      <!--      </div>-->
      <!-- if unit is cbm -> new input called number of bins (numeric type) -->
      <!--      <div-->
      <!--        v-if="formData.unit_id == 5"-->
      <!--        class="col-sm-6 col-md-6 col-lg-4 col-12"-->
      <!--      >-->
      <!--        <v-text-field-->
      <!--          v-model="formData.number_of_bins"-->
      <!--          label="Number of bins"-->
      <!--          clearable-->
      <!--          outlined-->
      <!--          type="Number"-->
      <!--          min="0"-->
      <!--          @change="() => validateMinValue('number_of_bins', 0)"-->
      <!--          :error-messages="number_of_binsErrors"-->
      <!--          @input="$v.formData.number_of_bins.$touch()"-->
      <!--          @blur="$v.formData.number_of_bins.$touch()"-->
      <!--        ></v-text-field>-->
      <!--      </div>-->

      <!--      <div class="col-sm-6 col-md-6 col-lg-4 col-12">-->
      <!--        <v-text-field-->
      <!--          v-model="formData.rate"-->
      <!--          label="Rate"-->
      <!--          clearable-->
      <!--          outlined-->
      <!--          type="Number"-->
      <!--          min="0"-->
      <!--          @change="() => validateMinValue('rate', 0)"-->
      <!--          :error-messages="rateErrors"-->
      <!--          @input="$v.formData.rate.$touch()"-->
      <!--          @blur="$v.formData.rate.$touch()"-->
      <!--        ></v-text-field>-->
      <!--      </div>-->
      <!--      <div class="col-sm-6 col-md-6 col-lg-4 col-12">-->
      <!--        <v-text-field-->
      <!--          v-model="formData.tax_percentage"-->
      <!--          label="Tax percentage"-->
      <!--          clearable-->
      <!--          outlined-->
      <!--          type="Number"-->
      <!--          min="0"-->
      <!--          @change="() => validateMinValue('tax_percentage', 0)"-->
      <!--          :error-messages="tax_percentageErrors"-->
      <!--          @input="$v.formData.tax_percentage.$touch()"-->
      <!--          @blur="$v.formData.tax_percentage.$touch()"-->
      <!--        ></v-text-field>-->
      <!--      </div>-->

      <!--      <div class="col-sm-6 col-md-6 col-lg-4 col-12">-->
      <!--        <v-text-field-->
      <!--          v-model="formData.buffer"-->
      <!--          label="Buffer"-->
      <!--          clearable-->
      <!--          outlined-->
      <!--          type="Number"-->
      <!--          min="0"-->
      <!--          max="100"-->
      <!--          suffix="%"-->
      <!--          @change="() => validateMinValue('buffer', 0)"-->
      <!--          :error-messages="bufferErrors"-->
      <!--          @input="$v.formData.buffer.$touch()"-->
      <!--          @blur="$v.formData.buffer.$touch()"-->
      <!--        ></v-text-field>-->
      <!--      </div>-->
      <!--
-->
      <!--      <div class="col-12 d-flex flex-wrap justify-center align-center">-->
      <!--        <div class="col-sm-6 col-md-6 col-lg-6 col-12">-->
      <!--          <v-radio-group label="Discount" v-model="formData.discount_type">-->
      <!--            <v-radio label="Flat rate" :value="0"></v-radio>-->
      <!--            <v-radio label="Percentage" :value="1"></v-radio>-->
      <!--          </v-radio-group>-->
      <!--        </div>-->
      <!--        <div class="col-sm-6 col-md-6 col-lg-6 col-12">-->
      <!--          <v-text-field-->
      <!--            v-model="formData.discount"-->
      <!--            :label="discountValueLabel"-->
      <!--            clearable-->
      <!--            outlined-->
      <!--            type="Number"-->
      <!--            min="0"-->
      <!--            @change="() => validateMinValue('discount', 0)"-->
      <!--            :error-messages="discountErrors"-->
      <!--            @input="$v.formData.discount.$touch()"-->
      <!--            @blur="$v.formData.discount.$touch()"-->
      <!--          ></v-text-field>-->
      <!--        </div>-->
      <!--      </div>-->
      <div class="col-sm-6 col-12">
        <v-autocomplete
          v-model="formData.warehouse_ids"
          class="multi-select"
          outlined
          :hide-no-data="!serverData.storage.warehouses"
          :items="serverData.storage.warehouses"
          label="Warehouses"
          item-text="text"
          item-value="index"
          multiple
          deletable-chips
          clearable
          chips
          small-chips
          :return-object="false"
          :error-messages="warehouse_idsErrors"
          @input="$v.formData.warehouse_ids.$touch()"
          @blur="$v.formData.warehouse_ids.$touch()"
        >
          <template v-slot:selection="{ attrs, item, parent, selected }">
            <v-chip
              v-if="item === Object(item)"
              v-bind="attrs"
              :input-value="selected"
              label
              small
            >
              <span class="pr-2">
                {{ item.text }}
              </span>
              <v-icon small @click="parent.selectItem(item)"> $delete </v-icon>
            </v-chip>
          </template>
        </v-autocomplete>
      </div>
      <div class="col-12 col-sm-6">
        <v-select
          v-model="formData.storage_item_id"
          label="Storage item"
          item-text="text"
          item-value="index"
          :items="serverData.storage.items"
          clearable
          outlined
          :error-messages="storage_item_idErrors"
          @input="$v.formData.storage_item_id.$touch()"
          @blur="$v.formData.storage_item_id.$touch()"
        ></v-select>
      </div>
      <div
        class="col-sm-6 col-md-6 col-12"
        v-if="formData.storage_item_id != 2"
      >
        <v-text-field
          v-model="formData.minimum_charge"
          label="Minimum fixed amount"
          clearable
          outlined
          type="Number"
          min="1"
          @change="() => validateMinValue('minimum_charge', 1)"
        ></v-text-field>
      </div>
      <div
        class="col-sm-6 col-md-6 col-lg-6 col-12"
        v-if="formData.storage_item_id == 2"
      >
        <v-text-field
          v-model="formData.pallet_rate"
          label="Pallet rate"
          clearable
          outlined
          type="Number"
          min="1"
          @change="() => validateMinValue('pallet_rate', 1)"
          :error-messages="pallet_rateErrors"
          @input="$v.formData.pallet_rate.$touch()"
          @blur="$v.formData.pallet_rate.$touch()"
        ></v-text-field>
      </div>
      <template v-if="formData.storage_item_id == 1">
        <div class="col-sm-6 col-md-6 col-12">
          <v-select
            v-model="formData.storage_type"
            label="Storage type"
            item-text="text"
            item-value="index"
            :items="serverData.storage.types"
            clearable
            outlined
            :error-messages="storage_typeErrors"
            @input="$v.formData.storage_type.$touch()"
            @blur="$v.formData.storage_type.$touch()"
          ></v-select>
        </div>
        <div class="col-sm-6 col-md-6 col-12">
          <v-select
            v-model="formData.volume_calculation"
            label="Volume calculation"
            item-text="text"
            item-value="index"
            :items="serverData.storage.volume_calculations"
            clearable
            outlined
            :error-messages="volume_calculationErrors"
            @input="$v.formData.volume_calculation.$touch()"
            @blur="$v.formData.volume_calculation.$touch()"
          ></v-select>
        </div>

        <div class="col-12 px-0 mx-0" v-if="formData.storage_item_id == 1">
          <rate-datatable
            name="rate"
            :setSku="setRates"
            :skus="formData.rates"
            :hasImport="true"
            :importType="1"
            importLabel="Import rates"
            :exampleFile="serverData?.storage?.rates_sample_file"
          ></rate-datatable>
        </div>
      </template>

      <v-main class="text-center mt-2 col-12 elevation-0">
        <button
          type="button"
          class="mx-1 btn btn-info px-5 py-3 ls1"
          @click="submitCreateForm"
        >
          Submit
        </button>
        <button
          type="button"
          class="mx-1 btn btn-light px-5 py-3 ls1"
          @click="resetCreateForm"
        >
          Clear
        </button>
        <button
          type="button"
          class="mx-1 btn btn-light px-5 py-3 ls1"
          @click="goBack"
        >
          Back
        </button>
      </v-main>
    </form>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, requiredIf } from "vuelidate/lib/validators";
import fieldValueValidation from "@/own/mixins/fieldValueValidation.js";
import RateDatatable from "@/own/components/finance/services/addForms/storageRateContainer/RateDatatable";
import Swal from "sweetalert2";
export default {
  name: "StorageForm.vue",
  components: { RateDatatable },
  mixins: [validationMixin, fieldValueValidation],
  props: ["setData", "actionFuntion", "serverData", "pageLoader", "initData"],
  validations() {
    return {
      formData: {
        // unit_id: { required },
        warehouse_ids: { required },
        // rate: { required },
        // tax_percentage: { required },
        // duration: { required },
        // minimum_charge: { required },
        // buffer: { required },
        // discount: { required },
        // number_of_bins: {
        //   required: requiredIf(() => {
        //     return this.formData.unit_id === 5;
        //   }),
        // },
        storage_item_id: { required },
        storage_type: {
          required: requiredIf(() => {
            return this.formData.storage_item_id == 1;
          }),
        },
        volume_calculation: {
          required: requiredIf(() => {
            return this.formData.storage_item_id == 1;
          }),
        },
        pallet_rate: {
          required: requiredIf(() => {
            return this.formData.storage_item_id == 2;
          }),
        },
      },
    };
  },
  data: () => ({
    formData: {
      id: null,
      // unit_id: null,
      warehouse_ids: null,
      // rate: null,
      // tax_percentage: null,
      // duration: 1,
      minimum_charge: null,
      // buffer: null,
      // discount_type: 0,
      // discount: null,
      // number_of_bins: null,
      storage_item_id: null,
      storage_type: null,
      volume_calculation: null,
      rates: [],
      pallet_rate: null,
    },
  }),
  methods: {
    setRates(item) {
      // console.log("before update", this.formData.rates, item);
      let copy = { ...this.formData };
      copy.rates = [...item];
      this.formData = copy;
      // console.log("after update", this.formData.rates);
    },
    goBack() {
      this.actionFuntion("back");
    },
    submitCreateForm() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      } else if (
        this.formData.rates.length === 0 &&
        this.formData.storage_item_id == 1
      ) {
        Swal.fire({
          icon: "warning",
          text: "The rate is required!",
        });
        return;
      } else {
        this.pageLoader(true);
        // let data = JSON.stringify(this.entCreate);

        const data = Object.fromEntries(
          Object.entries(this.formData).filter(([key, value]) => value !== null)
        );
        this.setData(data);
        this.actionFuntion();
        // this.resetCreateForm();
      }
    },
    resetCreateForm() {
      // console.log("reset create form");
      this.$v.$reset();
      if (this.initData) {
        try {
          let copy = { ...this.serverData.service.values };
          Object.entries(this.formData).forEach((localData) => {
            Object.entries(this.serverData.service.values).forEach(
              (serverData) => {
                if (localData[0] == serverData[0]) {
                  this.formData[localData[0]] = copy[serverData[0]];
                }
              }
            );
          });
        } catch (e) {
          // console.error(e);
          this.formData = {
            id: null,
            // unit_id: null,
            warehouse_ids: null,
            // rate: null,
            // tax_percentage: null,
            // duration: 1,
            minimum_charge: null,
            // buffer: null,
            // discount_type: 0,
            // discount: null,
            // number_of_bins: null,
            storage_item_id: null,
            storage_type: null,
            volume_calculation: null,
            rates: [],
            pallet_rate: null,
          };
        }
      } else {
        this.formData = {
          id: null,
          // unit_id: null,
          warehouse_ids: null,
          // rate: null,
          // tax_percentage: null,
          // duration: 1,
          minimum_charge: null,
          // buffer: null,
          // discount_type: 0,
          // discount: null,
          // number_of_bins: null,
          storage_item_id: null,
          storage_type: null,
          volume_calculation: null,
          rates: [],
          pallet_rate: null,
        };
      }
    },
    handleFormValidation(fieldName, vueObj) {
      const errors = [];
      if (!vueObj.$v.formData[fieldName].$dirty) return errors;
      if ("required" in vueObj.$v.formData[fieldName]) {
        !vueObj.$v.formData[fieldName].required &&
          errors.push("This field is required");
      }
      if ("url" in vueObj.$v.formData[fieldName]) {
        !vueObj.$v.formData[fieldName].url &&
          errors.push("This url is invalid");
      }
      if ("numeric" in vueObj.$v.formData[fieldName]) {
        !vueObj.$v.formData[fieldName].numeric &&
          errors.push("This must be a number");
      }
      if ("email" in vueObj.$v.formData[fieldName]) {
        !vueObj.$v.formData[fieldName].email &&
          errors.push("This email address is invalid");
      }
      return errors;
    },
  },
  computed: {
    // discountValueLabel() {
    //   return this.formData.discount_type == 0 ? "Flat rate" : "Percentage";
    // },
    // durationPlaceholder() {
    //   return this.serverData.duration;
    // },
    // unit_idErrors() {
    //   return this.handleFormValidation("unit_id", this);
    // },
    warehouse_idsErrors() {
      return this.handleFormValidation("warehouse_ids", this);
    },
    // rateErrors() {
    //   return this.handleFormValidation("rate", this);
    // },
    // tax_percentageErrors() {
    //   return this.handleFormValidation("tax_percentage", this);
    // },
    // durationErrors() {
    //   return this.handleFormValidation("duration", this);
    // },
    // minimum_chargeErrors() {
    //   return this.handleFormValidation("minimum_charge", this);
    // },
    // bufferErrors() {
    //   return this.handleFormValidation("buffer", this);
    // },
    // discountErrors() {
    //   return this.handleFormValidation("discount", this);
    // },
    // number_of_binsErrors() {
    //   return this.handleFormValidation("number_of_bins", this);
    // },
    storage_item_idErrors() {
      return this.handleFormValidation("storage_item_id", this);
    },
    storage_typeErrors() {
      return this.handleFormValidation("storage_type", this);
    },
    volume_calculationErrors() {
      return this.handleFormValidation("volume_calculation", this);
    },
    pallet_rateErrors() {
      return this.handleFormValidation("pallet_rate", this);
    },
  },
};
</script>
