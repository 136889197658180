<template>
  <div style="display: flex; flex-flow: wrap; flex-direction: column">
    <!--    <div class="py-4 text-h4">Delivery Form</div>-->
    <form id="createForm" class="pa-3 pt-4 pb-0 row elevation-0">
      <div class="col-sm-6 col-md-6 col-lg-4 col-12">
        <v-select
          v-model="formData.unit_id"
          label="Unit"
          item-text="text"
          item-value="index"
          :items="serverData.delivery.units"
          clearable
          outlined
          :error-messages="unit_idErrors"
          @input="$v.formData.unit_id.$touch()"
          @blur="$v.formData.unit_id.$touch()"
        ></v-select>
      </div>
      <div class="col-sm-6 col-md-6 col-lg-4 col-12">
        <v-select
          v-model="formData.order_date"
          label="Invoicing order date"
          item-text="text"
          item-value="index"
          :items="serverData.delivery.order_dates"
          clearable
          outlined
          :error-messages="order_dateErrors"
          @input="$v.formData.order_date.$touch()"
          @blur="$v.formData.order_date.$touch()"
        ></v-select>
      </div>
      <div class="col-sm-6 col-md-6 col-lg-4 col-12">
        <v-autocomplete
          v-model="formData.order_types"
          class="multi-select"
          outlined
          :hide-no-data="!serverData.delivery.order_types"
          :items="serverData.delivery.order_types"
          label="Order types"
          item-text="text"
          item-value="index"
          multiple
          deletable-chips
          clearable
          chips
          small-chips
          :return-object="false"
          :error-messages="order_typesErrors"
          @input="$v.formData.order_types.$touch()"
          @blur="$v.formData.order_types.$touch()"
        >
          <template v-slot:selection="{ attrs, item, parent, selected }">
            <v-chip
              v-if="item === Object(item)"
              v-bind="attrs"
              :input-value="selected"
              label
              small
            >
              <span class="pr-2">
                {{ item.text }}
              </span>
              <v-icon small @click="parent.selectItem(item)"> $delete </v-icon>
            </v-chip>
          </template>
        </v-autocomplete>
      </div>
      <div class="col-sm-6 col-md-6 col-lg-4 col-12">
        <v-autocomplete
          v-model="formData.origin_warehouse_ids"
          class="multi-select"
          outlined
          :hide-no-data="!serverData.delivery.warehouses"
          :items="serverData.delivery.warehouses"
          label="Origin warehouses"
          item-text="text"
          item-value="index"
          multiple
          deletable-chips
          clearable
          chips
          small-chips
          :return-object="false"
          :error-messages="origin_warehouse_idsErrors"
          @input="$v.formData.origin_warehouse_ids.$touch()"
          @blur="$v.formData.origin_warehouse_ids.$touch()"
        >
          <template v-slot:selection="{ attrs, item, parent, selected }">
            <v-chip
              v-if="item === Object(item)"
              v-bind="attrs"
              :input-value="selected"
              label
              small
            >
              <span class="pr-2">
                {{ item.text }}
              </span>
              <v-icon small @click="parent.selectItem(item)"> $delete </v-icon>
            </v-chip>
          </template>
        </v-autocomplete>
      </div>
      <div class="col-sm-6 col-md-6 col-lg-4 col-12">
        <v-select
          v-model="formData.delivery_vehicle_id"
          label="Delivery vehicle"
          item-text="text"
          item-value="index"
          :items="serverData.delivery.vehicles"
          clearable
          outlined
        ></v-select>
      </div>
      <div class="col-sm-6 col-md-6 col-lg-4 col-12">
        <v-select
          v-model="formData.delivery_vehicle_storage_type"
          label="Delivery vehicle storage type"
          item-text="text"
          item-value="index"
          :items="serverData.delivery.vehicle_storage_types"
          clearable
          outlined
          :error-messages="delivery_vehicle_storage_typeErrors"
          @input="$v.formData.delivery_vehicle_storage_type.$touch()"
          @blur="$v.formData.delivery_vehicle_storage_type.$touch()"
        ></v-select>
      </div>
      <div
        class="col-sm-6 col-md-6 col-lg-4 col-12"
        v-if="formData.delivery_vehicle_id == 1"
      >
        <v-text-field
          v-model="formData.delivery_fee"
          label="Delivery fee"
          clearable
          outlined
          type="Number"
          min="0"
          @change="() => validateMinValue('delivery_fee', 0)"
          :error-messages="delivery_feeErrors"
          @input="$v.formData.delivery_fee.$touch()"
          @blur="$v.formData.delivery_fee.$touch()"
        ></v-text-field>
      </div>
      <div class="col-sm-6 col-md-6 col-lg-4 col-12">
        <v-select
          v-model="formData.apply_conversion"
          label="Apply conversion"
          item-text="text"
          item-value="index"
          :items="[
            { text: 'Yes', index: 1 },
            { text: 'No', index: 0 },
          ]"
          outlined
        ></v-select>
      </div>
      <div class="col-sm-6 col-md-6 col-lg-4 col-12">
        <v-text-field
          v-model="formData.cod_price"
          label="COD price"
          clearable
          outlined
          type="Number"
          min="0"
          @change="() => validateMinValue('cod_price', 0)"
        ></v-text-field>
      </div>
      <div class="col-sm-6 col-md-6 col-lg-4 col-12">
        <v-text-field
          v-model="formData.cod_percentage"
          label="COD percentage"
          clearable
          outlined
          type="Number"
          min="0"
          @change="() => validateMinValue('cod_percentage', 0)"
        ></v-text-field>
      </div>
      <div class="col-sm-6 col-md-6 col-lg-4 col-12">
        <v-text-field
          v-model="formData.minimum_cod_charge"
          label="Min COD charge"
          clearable
          outlined
          type="Number"
          min="0"
          @change="() => validateMinValue('minimum_cod_charge', 0)"
          :error-messages="minimum_cod_chargeErrors"
          @input="$v.formData.minimum_cod_charge.$touch()"
          @blur="$v.formData.minimum_cod_charge.$touch()"
        ></v-text-field>
      </div>
      <div class="col-sm-6 col-md-6 col-lg-4 col-12">
        <v-text-field
          v-model="formData.max_cod_charge_threshold"
          label="Max COD charge threshold"
          clearable
          outlined
          type="Number"
          min="0"
          @change="() => validateMinValue('max_cod_charge_threshold', 0)"
          :error-messages="max_cod_charge_thresholdErrors"
          @input="$v.formData.max_cod_charge_threshold.$touch()"
          @blur="$v.formData.max_cod_charge_threshold.$touch()"
        ></v-text-field>
      </div>

      <div class="col-sm-6 col-md-6 col-lg-4 col-12">
        <v-select
          v-model="formData.shipment_type_id"
          label="Shipment type"
          item-text="text"
          item-value="index"
          :items="serverData.delivery.shipment_types"
          clearable
          outlined
        ></v-select>
      </div>

      <div class="col-sm-6 col-md-6 col-lg-4 col-12">
        <v-text-field
          v-model="formData.temperature_control_charge"
          label="Temperature control charge"
          clearable
          outlined
          type="Number"
          :min="0"
          @change="() => validateMinValue('temperature_control_charge', 0)"
        ></v-text-field>
      </div>
      <div class="col-sm-6 col-md-6 col-lg-4 col-12">
        <v-text-field
          v-model="formData.shipment_type_city_charge"
          label="Shipment type city charge"
          clearable
          outlined
          type="Number"
          :min="0"
          @change="() => validateMinValue('shipment_type_city_charge', 0)"
        ></v-text-field>
      </div>

      <!-- <div class="col-sm-6 col-md-6 col-lg-4 col-12">
        <v-text-field
          v-model="formData.remote_area"
          label="Remote area multiplier"
          clearable
          outlined
          type="Number"
          min="0"
        ></v-text-field>
      </div> -->
      <div class="col-sm-6 col-md-6 col-lg-4 col-12">
        <v-text-field
          v-model="formData.tax_percentage"
          label="Tax percentage"
          clearable
          outlined
          type="Number"
          min="0"
          @change="() => validateMinValue('tax_percentage', 0)"
          :error-messages="tax_percentageErrors"
          @input="$v.formData.tax_percentage.$touch()"
          @blur="$v.formData.tax_percentage.$touch()"
        ></v-text-field>
      </div>
      <div class="col-12">
        <v-autocomplete
          :loading="isLoading"
          v-model="formData.shipment_type_cities"
          :search-input.sync="city_search_text"
          label="Shipment type cities"
          item-text="text"
          item-value="index"
          :items="serverData.cities"
          clearable
          outlined
          multiple
          cache-items
          chips
          deletable-chips
          placeholder="Enter first two letters of city name"
        ></v-autocomplete>
      </div>
      <!--      <div-->
      <!--        class="col-12 col-sm-6 d-flex align-center justify-space-between mt-0 pt-0"-->
      <!--      >-->
      <!--        <h5 class="mb-0 mt-0 pt-0 font-weight-regular">-->
      <!--          Combine with fulfillment on invoice-->
      <!--        </h5>-->
      <!--        <v-switch-->
      <!--          v-model="formData.combine_with_fulfillment_on_invoice"-->
      <!--          dense-->
      <!--          hide-details-->
      <!--          class="mt-0 pt-0"-->
      <!--          true-value="1"-->
      <!--          false-value="0"-->
      <!--        />-->
      <!--      </div>-->
      <!--      <div class="col-12 d-flex flex-wrap justify-center align-center">-->
      <!--        <div class="col-sm-6 col-md-6 col-lg-6 col-12">-->
      <!--          <v-radio-group-->
      <!--            label="Remote area mode"-->
      <!--            v-model="formData.remote_area_type"-->
      <!--          >-->
      <!--            <v-radio label="Multiplier" :value="0"></v-radio>-->
      <!--            <v-radio label="Flat rate" :value="1"></v-radio>-->
      <!--          </v-radio-group>-->
      <!--        </div>-->
      <!--        <div class="col-sm-6 col-md-6 col-lg-6 col-12">-->
      <!--          <v-text-field-->
      <!--            v-model="formData.remote_area"-->
      <!--            :label="remoteAreaValueLabel"-->
      <!--            clearable-->
      <!--            outlined-->
      <!--            type="Number"-->
      <!--            min="0"-->
      <!--            @change="() => validateMinValue('remote_area', 0)"-->
      <!--          ></v-text-field>-->
      <!--        </div>-->
      <!--      </div>-->
      <div class="col-12 row py-0 px-0 my-0 mx-0">
        <div class="col-sm-6 col-md-6 col-lg-6 col-12">
          <v-radio-group
            row
            label="Discount"
            v-model="formData.discount_type"
            class="py-0 my-0"
          >
            <v-radio label="Flat rate" :value="0"></v-radio>
            <v-radio label="Percentage" :value="1"></v-radio>
          </v-radio-group>
        </div>
        <div class="col-sm-6 col-md-6 col-lg-6 col-12">
          <v-text-field
            v-model="formData.discount"
            :label="discountValueLabel"
            clearable
            outlined
            type="Number"
            min="0"
            @change="() => validateMinValue('discount', 0)"
          ></v-text-field>
        </div>
      </div>
      <div class="col-12 row py-0 px-0 my-0 mx-0">
        <div class="col-sm-6 col-md-6 col-lg-6 col-12">
          <v-radio-group
            row
            label="Remote area"
            v-model="formData.remote_area_charge_type"
            class="py-0 my-0"
          >
            <v-radio label="Flat rate" :value="0"></v-radio>
            <v-radio label="Percentage" :value="1"></v-radio>
            <v-radio label="Multiplier" :value="2"></v-radio>
          </v-radio-group>
        </div>
        <div class="col-sm-6 col-md-6 col-lg-6 col-12">
          <v-text-field
            v-model="formData.remote_area_charge"
            :label="remote_area_chargeValueLabel"
            clearable
            outlined
            type="Number"
            min="0"
            @change="() => validateMinValue('remote_area_charge', 0)"
          ></v-text-field>
        </div>
      </div>
      <div class="col-12 row pa-0 ma-0">
        <div class="col-12 col-sm-6">
          <v-radio-group
            label="Destination"
            v-model="formData.destination_type"
            row
            class="py-0 my-0"
            :error-messages="destination_typeErrors"
            @input="$v.formData.destination_type.$touch()"
            @blur="$v.formData.destination_type.$touch()"
            @change="destinationTypeUpdate"
          >
            <v-radio
              v-for="(desti, i) in serverData.delivery.destination_types"
              :label="desti.text"
              :value="desti.index"
              :key="i"
            ></v-radio>
          </v-radio-group>
        </div>

        <div class="col-12 col-sm-6">
          <v-autocomplete
            v-if="destination_type === 0"
            v-model="formData.destination_id"
            label="Country"
            item-text="text"
            item-value="index"
            :items="serverData.delivery.countries"
            clearable
            outlined
            :error-messages="destination_idErrors"
            @input="$v.formData.destination_id.$touch()"
            @blur="$v.formData.destination_id.$touch()"
          ></v-autocomplete
          ><v-autocomplete
            v-else-if="destination_type === 1"
            v-model="formData.destination_id"
            label="Regions"
            item-text="text"
            item-value="index"
            :items="serverData.delivery.regions"
            clearable
            outlined
            :error-messages="destination_idErrors"
            @input="$v.formData.destination_id.$touch()"
            @blur="$v.formData.destination_id.$touch()"
          ></v-autocomplete>
        </div>

        <!--        <div class="col-12">-->
        <!--          <v-radio-group-->
        <!--            label="Invoicing order status"-->
        <!--            v-model="formData.invoicing_order_status_type"-->
        <!--            row-->
        <!--          >-->
        <!--            <v-radio-->
        <!--              v-for="(desti, i) in [-->
        <!--                { text: 'Include', index: 0 },-->
        <!--                { text: 'Exclude', index: 1 },-->
        <!--              ]"-->
        <!--              :label="desti.text"-->
        <!--              :value="desti.index"-->
        <!--              :key="i"-->
        <!--            ></v-radio>-->
        <!--          </v-radio-group>-->
        <!--          <div>-->
        <!--            <v-autocomplete-->
        <!--              v-model="formData.invoicing_order_status"-->
        <!--              label="Invoicing order status"-->
        <!--              item-text="text"-->
        <!--              item-value="index"-->
        <!--              :items="serverData.invoicing_order_statuses"-->
        <!--              clearable-->
        <!--              outlined-->
        <!--              :error-messages="invoicing_order_statusErrors"-->
        <!--              @input="$v.formData.invoicing_order_status.$touch()"-->
        <!--              @blur="$v.formData.invoicing_order_status.$touch()"-->
        <!--            ></v-autocomplete>-->
        <!--          </div>-->
        <!--        </div>-->

        <!-- new radio btn invoicing order status -> include - exclude -->
        <!-- new dropdown : returned, under process, delivered -->
      </div>
      <div class="col-12">
        <rate-datatable
          name="rate"
          :setSku="setRates"
          :skus="formData.rates"
          :hasImport="true"
          :importType="1"
          :countryId="formData.destination_id"
          :isCountry="destination_type"
          importLabel="Import rates"
          :exampleFile="serverData?.delivery?.rates_sample_file"
        ></rate-datatable>
        <v-divider class="py-4"></v-divider>

        <rate-datatable
          name="increment"
          :setSku="setIncrements"
          :skus="formData.increments"
          :hasImport="true"
          :importType="2"
          :countryId="formData.destination_id"
          :isCountry="destination_type"
          importLabel="Import increment"
          :exampleFile="serverData?.delivery?.increments_sample_file"
        ></rate-datatable>
        <v-divider class="py-4"></v-divider>
        <cost-datatable
          name="additional costs"
          :setSku="setCosts"
          :skus="formData.additional_costs"
          importLabel="Import additional costs"
          :applyOns="applyOns"
        ></cost-datatable>

        <!-- need to add new section additional cost-> fields name,rate, apply on,apply on position -->
      </div>
      <v-main class="text-center mt-2 col-12 elevation-0">
        <button
          type="button"
          class="mx-1 btn btn-info px-5 py-3 ls1"
          @click="submitCreateForm"
        >
          Submit
        </button>
        <button
          type="button"
          class="mx-1 btn btn-light px-5 py-3 ls1"
          @click="resetCreateForm"
        >
          Clear
        </button>
        <button
          type="button"
          class="mx-1 btn btn-light px-5 py-3 ls1"
          @click="goBack"
        >
          Back
        </button>
      </v-main>
    </form>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import RateDatatable from "@/own/components/finance/services/addForms/drateContainer/RateDatatable";
import CostDatatable from "@/own/components/finance/services/addForms/costContainer/CostDatatable";
import { required, requiredIf } from "vuelidate/lib/validators";
import fieldValueValidation from "@/own/mixins/fieldValueValidation.js";
import ApiService from "@/core/services/api.service";
import Swal from "sweetalert2";
export default {
  name: "DeliveryForm.vue",
  components: { RateDatatable, CostDatatable },
  mixins: [validationMixin, fieldValueValidation],
  props: ["setData", "actionFuntion", "serverData", "pageLoader", "initData"],
  validations() {
    return {
      formData: {
        origin_warehouse_ids: { required },
        destination_id: { required },
        destination_type: { required },
        order_types: { required },
        unit_id: { required },
        order_date: { required },
        tax_percentage: { required },
        // invoicing_order_status: {
        //   required: requiredIf(() => {
        //     return this.formData.invoicing_order_status_type === 0;
        //   }),
        // },
        delivery_fee: {
          required: requiredIf(() => {
            return this.formData.delivery_vehicle_id;
          }),
        },
        minimum_cod_charge: { required },
        max_cod_charge_threshold: { required },
        delivery_vehicle_storage_type: {
          required: requiredIf(() => {
            return this.formData.delivery_vehicle_id;
          }),
        },
      },
    };
  },
  data: () => ({
    city_search_text: "",
    doneTypingInterval: 700,
    typingTimer: null,
    isLoading: false,
    formData: {
      id: null,
      unit_id: null,
      origin_warehouse_ids: [],
      destination_type: 0,
      destination_id: null,
      cod_price: null,
      cod_percentage: null,
      // remote_area: null,
      tax_percentage: null,
      order_date: null,
      order_types: null,

      rates: [],
      increments: [],
      additional_costs: [],
      // remote_area_type: 0,
      discount_type: 0,
      discount: null,
      // combine_with_fulfillment_on_invoice: null,
      // invoicing_order_status_type: 0,
      // invoicing_order_status: null,
      delivery_vehicle_id: null,
      delivery_fee: null,
      apply_conversion: 0,
      minimum_cod_charge: 0,
      max_cod_charge_threshold: 0,
      temperature_control_charge: 0,
      shipment_type_id: null,
      shipment_type_cities: [],
      remote_area_charge: null,
      remote_area_charge_type: 0,
      shipment_type_city_charge: null,
      delivery_vehicle_storage_type: null,
    },
  }),
  methods: {
    async getCitiesFromServer(search_text) {
      this.pageLoader(true);
      await ApiService.post("/address/cities/search", {
        q: search_text,
        country_label: 1,
      })
        .then((response) => {
          this.serverData.cities = response.data.cities;
          this.pageLoader(false);
          this.isLoading = false;
        })
        .catch(() => {
          this.pageLoader(false);
          this.isLoading = false;
        });
    },
    destinationTypeUpdate() {
      this.formData.destination_id = null;
    },
    setIncrements(item) {
      // console.log("before update", this.formData.increments, item);
      let copy = { ...this.formData };
      copy.increments = [...item];
      this.formData = copy;
      // console.log("after update", this.formData.increments);
    },
    setCosts(item) {
      // console.log("before update", this.formData.increments, item);
      let copy = { ...this.formData };
      copy.additional_costs = [...item];
      this.formData = copy;
      // console.log("after update", this.formData.increments);
    },
    setRates(item) {
      // console.log("before update", this.formData.rates, item);
      let copy = { ...this.formData };
      copy.rates = [...item];
      this.formData = copy;
      // console.log("after update", this.formData.rates);
    },
    goBack() {
      this.actionFuntion("back");
    },

    submitCreateForm() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      } else if (
        this.formData.increments.length === 0 &&
        this.formData.rates.length === 0
      ) {
        Swal.fire({
          icon: "warning",
          text: "Rate or increment is required!",
        });
      } else {
        this.pageLoader(true);
        // let data = JSON.stringify(this.entCreate);
        const data = { ...this.formData };
        this.setData(data);
        this.actionFuntion();
        // this.resetCreateForm();
      }
    },
    resetCreateForm() {
      this.$v.$reset();
      if (this.initData) {
        try {
          let copy = { ...this.serverData.service.values };
          Object.entries(this.formData).forEach((localData) => {
            Object.entries(this.serverData.service.values).forEach(
              (serverData) => {
                if (localData[0] == serverData[0]) {
                  this.formData[localData[0]] = copy[serverData[0]];
                }
              }
            );
          });
          this.serverData.cities = this.formData.shipment_type_cities;
          this.formData.shipment_type_cities =
            this.formData.shipment_type_cities.map((city) => city.index);
        } catch (e) {
          // console.error(e);
          this.formData = {
            id: null,
            unit_id: null,
            origin_warehouse_ids: [],
            destination_type: 0,
            destination_id: null,
            cod_price: null,
            cod_percentage: null,
            // remote_area: null,
            tax_percentage: null,
            order_date: null,
            order_types: null,
            rates: [],
            increments: [],
            additional_costs: [],
            // remote_area_type: 0,
            discount_type: 0,
            discount: null,
            // combine_with_fulfillment_on_invoice: null,
            // invoicing_order_status_type: 0,
            // invoicing_order_status: null,
            delivery_vehicle_id: null,
            delivery_fee: null,
            apply_conversion: 0,
            minimum_cod_charge: 0,
            max_cod_charge_threshold: 0,
            temperature_control_charge: 0,
            shipment_type_id: null,
            shipment_type_cities: [],
            remote_area_charge: null,
            remote_area_charge_type: 0,
            shipment_type_city_charge: null,
            delivery_vehicle_storage_type: null,
          };
        }
      } else {
        this.formData = {
          id: null,
          unit_id: this.serverData.delivery.units[0].index,
          origin_warehouse_ids: [],
          destination_type: 0,
          destination_id: null,
          cod_price: null,
          cod_percentage: null,
          // remote_area: null,
          tax_percentage: null,
          order_date: null,
          order_types: null,
          rates: [],
          increments: [],
          additional_costs: [],
          // remote_area_type: 0,
          discount_type: 0,
          discount: null,
          // combine_with_fulfillment_on_invoice: null,
          // invoicing_order_status_type: 0,
          // invoicing_order_status: null,
          delivery_vehicle_id: null,
          delivery_fee: null,
          apply_conversion: 0,
          minimum_cod_charge: 0,
          max_cod_charge_threshold: 0,
          temperature_control_charge: 0,
          shipment_type_id: null,
          shipment_type_cities: [],
          remote_area_charge: null,
          remote_area_charge_type: 0,
          shipment_type_city_charge: null,
          delivery_vehicle_storage_type: null,
        };
      }
    },
    handleFormValidation(fieldName, vueObj) {
      const errors = [];
      if (!vueObj.$v.formData[fieldName].$dirty) return errors;
      if ("required" in vueObj.$v.formData[fieldName]) {
        !vueObj.$v.formData[fieldName].required &&
          errors.push("This field is required");
      }
      if ("url" in vueObj.$v.formData[fieldName]) {
        !vueObj.$v.formData[fieldName].url &&
          errors.push("This url is invalid");
      }
      if ("numeric" in vueObj.$v.formData[fieldName]) {
        !vueObj.$v.formData[fieldName].numeric &&
          errors.push("This must be a number");
      }
      if ("email" in vueObj.$v.formData[fieldName]) {
        !vueObj.$v.formData[fieldName].email &&
          errors.push("This email address is invalid");
      }
      return errors;
    },
  },
  computed: {
    applyOns() {
      return this.serverData.delivery.apply_ons;
    },
    // remoteAreaValueLabel() {
    //   return this.formData.remote_area_type == 0 ? "Multiplier" : "Flat rate";
    // },
    discountValueLabel() {
      return this.formData.discount_type == 0 ? "Flat rate" : "Percentage";
    },
    remote_area_chargeValueLabel() {
      return this.formData.remote_area_charge == 0
        ? "Flat rate"
        : this.formData.remote_area_charge == 1
        ? "Percentage"
        : "Multiplier";
    },
    destination_type: function () {
      return this.formData.destination_type;
    },
    // invoicing_order_status_type() {
    //   return this.formData.invoicing_order_status_type;
    // },
    // invoicing_order_statusErrors() {
    //   return this.handleFormValidation("invoicing_order_status", this);
    // },
    origin_warehouse_idsErrors() {
      return this.handleFormValidation("origin_warehouse_ids", this);
    },
    destination_idErrors() {
      return this.handleFormValidation("destination_id", this);
    },
    destination_typeErrors() {
      return this.handleFormValidation("destination_type", this);
    },
    order_typesErrors() {
      return this.handleFormValidation("order_types", this);
    },
    unit_idErrors() {
      return this.handleFormValidation("unit_id", this);
    },
    order_dateErrors() {
      return this.handleFormValidation("order_date", this);
    },
    tax_percentageErrors() {
      return this.handleFormValidation("tax_percentage", this);
    },
    // nameErrors() {
    //   return this.handleFormValidation("name", this);
    // },
    delivery_feeErrors() {
      return this.handleFormValidation("delivery_fee", this);
    },
    minimum_cod_chargeErrors() {
      return this.handleFormValidation("minimum_cod_charge", this);
    },
    max_cod_charge_thresholdErrors() {
      return this.handleFormValidation("max_cod_charge_threshold", this);
    },
    delivery_vehicle_storage_typeErrors() {
      return this.handleFormValidation("delivery_vehicle_storage_type", this);
    },
  },
  watch: {
    city_search_text(val) {
      clearTimeout(this.typingTimer);
      this.isLoading = true;
      if (val && val != "" && [...val].length > 1) {
        this.typingTimer = setTimeout(() => {
          this.getCitiesFromServer(val.trim());
        }, this.doneTypingInterval);
      } else {
        this.isLoading = false;
        return;
      }
    },
  },
};
</script>
