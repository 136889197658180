<template>
  <div class="p-4 bg-white rounded-lg" v-if="isDataLoaded">
    <DataTable
      ref="datatable"
      :table_state="table_state"
      :store_names="store_names"
      :addComponent="componentForAdding"
      :editComponent="componentForEditing"
      :duplicateComponent="componentForDuplicating"
      :delete_url="delete_url"
      :urls="urls"
      :actionsComponent="null"
    >
      <template v-slot:rowActions="{ item, permissions }">
        <v-menu left transition="scale-transition" offset-y bottom rounded>
          <template v-slot:activator="{ on, attrs }">
            <a
              v-bind="attrs"
              v-on="on"
              class="btn btn-light-light btn-hover-info show poppins min-w-100px min-w-md-110px"
            >
              Actions
              <span class="svg-icon svg-icon-5 m-0">
                <v-icon small>mdi-chevron-down</v-icon>
              </span>
            </a>
          </template>
          <v-card class="poppins py-1" elevation="1">
            <div v-if="permissions('status')">
              <v-btn
                class="w-100 text-left"
                :disabled="!item.extra_data.actions.disable"
                :class="
                  !item.extra_data.actions.disable ? 'text-muted' : 'text-dark'
                "
                @click="
                  () => {
                    actionStatus(item);
                  }
                "
                text
                >{{
                  item.status_label == "Verified" ||
                  item.status_label == "Expired"
                    ? "Disable"
                    : "Verify"
                }}
              </v-btn>
            </div>
            <div v-if="permissions('update')">
              <v-btn
                class="w-100 text-left"
                :disabled="!item.extra_data.actions.edit"
                :class="
                  !item.extra_data.actions.edit ? 'text-muted' : 'text-dark'
                "
                @click="
                  () => {
                    actionEdit(item);
                  }
                "
                text
                >Edit
              </v-btn>
            </div>
            <div v-if="permissions('update')">
              <v-btn
                class="w-100 text-left"
                :disabled="!item.extra_data.actions.duplicate"
                :class="
                  !item.extra_data.actions.duplicate
                    ? 'text-muted'
                    : 'text-dark'
                "
                @click="
                  () => {
                    actionDuplicate(item);
                  }
                "
                text
                >Duplicate
              </v-btn>
            </div>
          </v-card>
        </v-menu>
      </template>
    </DataTable>
  </div>
</template>

<script>
import DataTable from "@/own/components/DataTable.vue";
import AddItem from "@/own/components/finance/customers/AddItem.vue";
import EditItem from "@/own/components/finance/customers/EditItem";
import DuplicateItem from "@/own/components/finance/customers/DuplicateItem";

import { SET_PAGE_LOADING } from "@/core/services/store/config.module";
import { SET_ITEM_FOR_ACTION } from "@/core/services/store/main.module";
import {
  EXPORT_TABLE_DATA,
  SET_TABLE_FILTER,
  SET_TABLE_PAGE,
  SET_TABLE_PERPAGE,
  SET_TABLE_SORTBY,
  UPDATE_TABLE_DATA,
  UPDATE_TABLE_STRUCTURE,
} from "@/core/services/store/fincustomer.module";
export default {
  name: "FinCustomer",
  components: { DataTable },
  data: () => ({
    componentForEditing: EditItem,
    componentForAdding: AddItem,
    componentForDuplicating: DuplicateItem,
    delete_url: `${process.env.VUE_APP_BASE_URL}/cod/contracts/destroy`,
    table_state: {
      UPDATE_TABLE_STRUCTURE: UPDATE_TABLE_STRUCTURE,
      UPDATE_TABLE_DATA: UPDATE_TABLE_DATA,
      SET_TABLE_SORTBY: SET_TABLE_SORTBY,
      SET_TABLE_FILTER: SET_TABLE_FILTER,
      SET_TABLE_PERPAGE: SET_TABLE_PERPAGE,
      SET_TABLE_PAGE: SET_TABLE_PAGE,
      EXPORT_TABLE_DATA: EXPORT_TABLE_DATA,
    },
    store_names: {
      getTableFilters: "getFINCUSTOMERTableFilters",
      getTableState: "getFINCUSTOMERTableState",
      getTableData: "getFINCUSTOMERTableData",
      getTableHeaders: "getFINCUSTOMERTableHeaders",
      getTableProperties: "getFINCUSTOMERTableProperties",
      getTableExportUrl: "getFINCUSTOMERExportUrl",
      getTablePermissions: "getFINCUSTOMERTablePermissions",
    },
    urls: {
      delete: `${process.env.VUE_APP_BASE_URL}/cod/contracts/destroy`,
      activate: `${process.env.VUE_APP_BASE_URL}/authorizations/users/activate`,
      status: `${process.env.VUE_APP_BASE_URL}/cod/contracts/state`,
      duplicate: `${process.env.VUE_APP_BASE_URL}/cod/contracts/duplicate`,
    },
  }),
  methods: {
    async initTableContent() {
      await this.$store.dispatch(this.table_state.UPDATE_TABLE_STRUCTURE, {});
      await this.$store.dispatch(this.table_state.UPDATE_TABLE_DATA, {});
    },
    actionEdit(item) {
      this.$store.commit(SET_ITEM_FOR_ACTION, item);
      this.$refs.datatable.$refs.editModal.toggleModal();
    },
    actionDuplicate(item) {
      this.$store.commit(SET_ITEM_FOR_ACTION, item);
      this.$refs.datatable.$refs.duplicateModal.toggleModal();
    },
    actionStatus(item) {
      this.$refs.datatable.statusActionNew(item, null);
    },
  },
  computed: {
    isDataLoaded: function () {
      return this.$store.getters[this.store_names.getTableData];
    },
  },
  beforeMount() {
    this.$store.commit(SET_PAGE_LOADING, true);
    this.initTableContent();
  },
};
</script>
